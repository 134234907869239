import {Fragment} from 'react'
import Avatar from '@components/avatar'
import {toast} from 'react-toastify'
import {Bell, Check, X, AlertTriangle, Info} from 'react-feather'

// ** Reactstrap Imports
import {Card, CardHeader, CardBody, CardTitle, Button} from 'reactstrap'

const LoadingToast = ({msg}) => (
    <Fragment>
        <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {msg}
      </span>
        </div>
    </Fragment>
)

const SuccessToast = ({msg}) => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12}/>}/>
                <h6 className="toast-title">Succès!</h6>
            </div>
        </div>
        <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {msg}
      </span>
        </div>
    </Fragment>
)

const ErrorToast = ({msg}) => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12}/>}/>
                <h6 className="toast-title">Error!</h6>
            </div>
        </div>
        <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {msg}.
      </span>
        </div>
    </Fragment>
)

const WarningToast = () => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar size="sm" color="warning" icon={<AlertTriangle size={12}/>}/>
                <h6 className="toast-title">Warning!</h6>
            </div>
            <small className="text-muted">11 Min Ago</small>
        </div>
        <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        👋 Jelly-o macaroon brownie tart ice cream croissant jelly-o apple pie.
      </span>
        </div>
    </Fragment>
)

const InfoToast = () => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar size="sm" color="info" icon={<Info size={12}/>}/>
                <h6 className="toast-title">Info!</h6>
            </div>
            <small className="text-muted">11 Min Ago</small>
        </div>
        <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        👋 Jelly-o macaroon brownie tart ice cream croissant jelly-o apple pie.
      </span>
        </div>
    </Fragment>
)

export const notifySuccess = (msg) => toast.success(<SuccessToast msg={msg}/>, {
    icon: false,
    hideProgressBar: true
})

export const notifyLoading = (msg) => toast.success(<LoadingToast msg={msg}/>, {
    icon: true,
    isLoading: true,
    hideProgressBar: false
})

export const notifyError = (msg) => toast.error(<ErrorToast msg={msg}/>, {icon: false, hideProgressBar: true})
export const notifyWarning = () => toast.warning(<WarningToast/>, {icon: false, hideProgressBar: true})
export const notifyInfo = () => toast.info(<InfoToast/>, {icon: false, hideProgressBar: true})
