// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axios from "axios"

const config = useJwt.jwtConfig

const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

const initialUserToken = () => {
    const item = window.localStorage.getItem('token')
    //** Parse stored json or if none return initialValue
    return item ? item : null
}

const initialDelegateToken = () => {
    const item = window.localStorage.getItem('current_user')
    //** Parse stored json or if none return initialValue
    return item ? item : null
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser(),
        token: initialUserToken(),
        current_user: initialDelegateToken()
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload
            state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
            state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]

            if (action.payload[config.current_user]) {
                state[config.current_user] = action.payload[config.current_user]
                localStorage.setItem(config.current_user, action.payload[config.current_user])
            }

            localStorage.setItem('userData', JSON.stringify(action.payload))
            localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
            localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
        },
        handleLogout: state => {
            state.userData = {}
            state[config.storageTokenKeyName] = null
            state[config.storageRefreshTokenKeyName] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
            localStorage.removeItem(config.current_user)
            delete axios.defaults.headers.common.Authorization
            delete axios.defaults.headers.common[config.current_user]
        }
    }
})

export const {handleLogin, handleLogout} = authSlice.actions

export default authSlice.reducer
