// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import {apiService} from "../../../services"
import React from "react"
import {notifyLoading} from "../../../@core/components/toasts/Toast"

export const loadSettings = createAsyncThunk('settings/load', async () => {
    const response = await apiService.get('settings', {})
    return response.data
})

//****************************************** ROLES ******************************************

export const loadRoles = createAsyncThunk('roles/load', async () => {
    const response = await apiService.get('role', {})
    return response.data
})

export const addRole = createAsyncThunk('roles/add', async (data) => {
    const response = await apiService.post('role', data)
    return response.data
})

export const deleteRole = createAsyncThunk('roles/delete', async ({id}) => {
    const response = await apiService.delete(`role/${id}`)
    return response.data
})

export const updateRole = createAsyncThunk('roles/edit', async ({id, permissions, lib}) => {
    const response = await apiService.put(`role/${id}`, {lib, permissions})
    return response.data
})

//****************************************** WorkFlows ******************************************


export const loadWorkflow = createAsyncThunk('workflowsLoad', async () => {
    const response = await apiService.get('supplier_workflow', {})
    return response.data
})

export const addWorkflow = createAsyncThunk('workflowsAdd', async (data) => {
    const response = await apiService.post('workflows', data)
    return response.data
})

export const deleteWorkflow = createAsyncThunk('workflowsDelete', async ({id}) => {
    const response = await apiService.delete(`workflows/${id}`)
    return response.data
})

export const updateWorkflow = createAsyncThunk('workflowsedit', async ({id, ...data}) => {
    const response = await apiService.put(`workflows/${id}`, data)
    return response.data
})

export const reOrderWorkflow = createAsyncThunk('workflowsReOrder', async (data) => {
    const response = await apiService.post(`workflows/re-order`, {workflows: data})
    return response.data
})

//****************************************** Managers ******************************************

export const loadManagers = createAsyncThunk('managersLoad', async () => {
    const response = await apiService.get('managers', {})
    return response.data
})

export const addManager = createAsyncThunk('managerAdd', async ({...data}) => {
    const response = await apiService.post('managers', data)
    return response.data
})

export const deleteManagers = createAsyncThunk('managerDelete', async ({id}) => {
    const response = await apiService.delete(`managers/${id}`)
    return response.data
})

export const updateManager = createAsyncThunk('managersEdit', async ({id, ...data}) => {
    const response = await apiService.put(`managers/${id}`, data)
    return response.data
})

//****************************************** Managers ******************************************


export const loadUsers = createAsyncThunk('usersLoad', async () => {
    const response = await apiService.get('users', {})
    return response.data
})

export const addUser = createAsyncThunk('usersAdd', async ({...data}) => {
    const response = await apiService.post('users', data)
    return response.data
})

export const deleteUser = createAsyncThunk('usersDelete', async ({id}) => {
    const response = await apiService.delete(`users/${id}`)
    return response.data
})

export const updateUser = createAsyncThunk('usersEdit', async ({id, ...data}) => {
    const response = await apiService.put(`users/${id}`, data)
    return response.data
})

//****************************************** Societie ******************************************


export const loadSocities = createAsyncThunk('societiesLoad', async () => {
    const response = await apiService.get('users', {})
    return response.data
})

export const addSociety = createAsyncThunk('societyAdd', async ({...data}) => {
    const response = await apiService.post('societies', data)
    return response.data
})

export const deleteSociety = createAsyncThunk('societyDelete', async ({id}) => {
    const response = await apiService.delete(`societies/${id}`)
    return response.data
})

export const updateSociety = createAsyncThunk('societyEdit', async ({id, ...data}) => {
    const response = await apiService.put(`societies/${id}`, data)
    return response.data
})

export const appSettingsSlice = createSlice({
    name: 'appRoles',
    progress: true,
    initialState: {
        roles: [],
        permissions: [],
        users: [],
        dedaAttributes: [],
        societies: [],
        operators: [],
        workflows: [],
        managers: [],
        success: "",
        error: ""
    },
    reducers: {
        updateRoleProgress: (state, action) => {
            state.progress = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        setSupplierWorkflows: (state, action) => {
            state.workflows = action.payload
        },
        setManagers: (state, action) => {
            state.managers = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        }
    },
    extraReducers: builder => {
        builder
            //****************************** Settings ****************************//
            .addCase(loadSettings.pending, () => {
                notifyLoading('Chargement des paramètres en cours')
            })
            .addCase(loadSettings.rejected, () => {
                state.error = "Problème de chargement des paramètres"
            })
            .addCase(loadSettings.fulfilled, (state, action) => {
                state.roles = action.payload.roles
                state.permissions = action.payload.permissions
                state.users = action.payload.users
                state.societies = action.payload.societies
                state.workflows = action.payload.workflows
                state.managers = action.payload.managers
                state.dedaAttributes = action.payload.dedaAttributes
                state.operators = action.payload.operators
                state.initializing = false
                state.success = "paramètres chargés avec succès"
                state.progress = false
            })

            //****************************** Roles ****************************//
            .addCase(loadRoles.fulfilled, (state, action) => {
                state.roles = action.payload.roles
                state.permissions = action.payload.permissions

                state.progress = false
            })
            .addCase(addRole.fulfilled, (state, action) => {
                const roles = state.roles
                roles.push(action.payload)
                state.roles = roles
                state.success = "Role ajouté avec succès"
                state.progress = false
            })
            .addCase(deleteRole.fulfilled, (state) => {
                state.progress = false
                state.success = "Role supprimé avec succès"
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.roles = state.roles.map(e => (e.id === action.payload.id ? {
                    ...e,
                    lib: action.payload.lib,
                    permissions: action.payload.permissions
                } : e))
                state.progress = false
                state.success = "Role modifié avec succès"
            })

            //****************************** Workflows ****************************//
            .addCase(loadWorkflow.fulfilled, (state, action) => {
                state.workflows = action.payload
                state.progress = false
            })
            .addCase(addWorkflow.fulfilled, (state, action) => {
                const workflows = state.workflows
                workflows.push(action.payload)
                state.workflows = workflows
                state.progress = false
                state.success = "workflow ajouté avec succès "
            })

            .addCase(addWorkflow.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(deleteWorkflow.fulfilled, (state) => {
                state.progress = false
                state.success = "workflow supprimé avec succès "
            })
            .addCase(deleteWorkflow.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(updateWorkflow.fulfilled, (state, action) => {
                state.workflows = state.workflows.map(e => (e.id === action.payload.id ? {
                    ...action.payload
                } : e))
                state.progress = false
                state.success = "workflow mis à jour avec succès "
            })
            .addCase(updateWorkflow.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(reOrderWorkflow.fulfilled, (state) => {
                //state.workflows = action.payload
                state.progress = false
                state.success = "workflow mis à jour avec succès "
            })
            .addCase(reOrderWorkflow.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            //****************************** Socities ****************************//
            .addCase(loadSocities.fulfilled, (state, action) => {
                state.societies = action.payload
                state.progress = false

            })
            .addCase(addSociety.fulfilled, (state, action) => {
                const societies = state.societies
                societies.push(action.payload)
                state.societies = societies
                state.progress = false
                state.success = "Société ajouté avec succès "
            })
            .addCase(addSociety.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(updateSociety.fulfilled, (state, action) => {
                state.societies = state.societies.map((society => (society.id === action.payload?.id ? action.payload : society)))
                state.success = "Société modifié avec succès"
                state.progress = false
            })
            .addCase(deleteSociety.fulfilled, (state, action) => {
                let societies = state.societies
                // eslint-disable-next-line
                societies = societies.filter(society => society?.id != action.payload?.id)
                state.societies = societies
                state.progress = false
                state.success = "Société supprimé avec succès "
            })

            //****************************** Managers ****************************//
            .addCase(loadManagers.fulfilled, (state, action) => {
                state.managers = action.payload
                state.progress = false

            })
            .addCase(addManager.fulfilled, (state, action) => {
                const managers = state.managers
                managers.push(action.payload)
                state.managers = managers
                state.progress = false
                state.success = "Gestionnaire ajouté avec succès "
            })
            .addCase(addManager.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(deleteManagers.fulfilled, (state, action) => {
                state.progress = false
                state.managers = state.managers.filter(manager => manager.id !== action.payload?.id)
                state.success = "Gestionnaire supprimé avec succès "
            })
            .addCase(deleteManagers.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(updateManager.fulfilled, (state, action) => {
                state.managers = state.managers.map(e => (e.id === action.payload.manager.id ? {
                    ...action.payload.manager
                } : e))
                if (action.payload.manager.parent) {
                    state.managers = state.managers.map(e => (e.id === action.payload.manager.parent.id ? {
                        ...e,
                        // eslint-disable-next-line multiline-ternary
                        children: e.children.some(manager => manager.id === action.payload.manager.id) ?
                            // eslint-disable-next-line multiline-ternary
                            e.children.map(e => (e.id === action.payload.manager.id ? {
                                ...action.payload.manager
                            } : e))
                            : [...e.children, action.payload.manager]
                    } : e))
                }

                if (action.payload.oldParent) {
                    state.managers = state.managers.map(e => (e.id === action.payload.oldParent?.id ? {
                        ...e,
                        // eslint-disable-next-line multiline-ternary
                        children: e.children.filter(manager => manager.id !== action.payload.manager.id)
                    } : e))
                }
                state.success = "Gestionnaire mis à jour avec succès "
                state.progress = false
            })
            .addCase(updateManager.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            //****************************** Users ****************************//
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.users = action.payload
                state.progress = false
            })
            .addCase(loadUsers.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(addUser.fulfilled, (state, action) => {
                const users = state.users
                users.push(action.payload)
                state.roles = state.roles.map(role => (role.id === action.payload?.role.id ? {
                    ...role,
                    users: role.users.concat(action.payload)
                } : role))
                state.users = users
                state.progress = false
                state.success = "Utilisateur ajouté avec succès "
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.progress = false
                state.users = state.users.filter(u => u.id !== action.payload.id)
                state.roles = state.roles.map(role => (role.id === action.payload?.role.id ? {
                    ...role,
                    users: role.users.filter(user => (user.id !== action.payload.id))
                } : role))
                state.success = "Utilisateur supprimé avec succès "
            })
            .addCase(deleteUser.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.users = state.users.map(e => (e.id === action.payload.id ? {
                    ...action.payload
                } : e))
                state.roles = state.roles.map(role => (role.id === action.payload?.role.id ? {
                    ...role,
                    users: role.users.map(user => (user.id === action.payload.id ? action.payload : user))
                } : {
                    ...role, users: role.users.filter(u => u.id !== action.payload.id)
                }))
                state.progress = false
                state.success = "Utilisateur mis à jour avec succès "
            })
            .addCase(updateUser.rejected, (state) => {
                state.error = "Un problème est survenu lors de l'opération"
                state.progress = false
            })
    }
})

export const {setRoles, setSuccess, setSupplierWorkflows, setManagers, updateRoleProgress} = appSettingsSlice.actions

export default appSettingsSlice.reducer
