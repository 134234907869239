import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {apiService} from "../../../services"

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false
    counter = 0;
    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

        // ** Request Interceptor
        axios.interceptors.request.use(config => {
            // ** Get token from localStorage
            const accessToken = this.getToken()

            // ** If token is present add it to request's Authorization Header
            if (accessToken) {
                // ** eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            }

            return config
        }, error => Promise.reject(error))

        // ** Add request/response interceptor
        axios.interceptors.response.use(response => response, error => {
            // ** const { config, response: { status } } = error
            const {config, response} = error
            const originalRequest = config

            // ** if (status === 401) {
            if (response && response.status === 401) {
                if (this.counter > 2) return

                this.counter++
                if (!this.isAlreadyFetchingAccessToken) {
                    this.isAlreadyFetchingAccessToken = true
                    this.refreshToken().then(r => {
                        this.isAlreadyFetchingAccessToken = false

                        // ** Update accessToken in localStorage
                        this.setToken(r.data.accessToken)
                        this.setRefreshToken(r.data.refreshToken)
                        this.onAccessTokenFetched(r.data.accessToken)
                    })
                }

                const retryOriginalRequest = new Promise(resolve => {
                    this.addSubscriber(accessToken => {
                        // ** Make sure to assign accessToken according to your response.
                        // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                        // ** Change Authorization header
                        originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                        resolve(axios(originalRequest))
                    })
                })
                return retryOriginalRequest
            }

            if (response && response.status == 403) {
                this.logout()
            }

            return Promise.reject(error)
        })
    }

    logout() {
        localStorage.removeItem('userData')
        localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem(this.jwtConfig.current_user)
        delete axios.defaults.headers.common.Authorization
        delete axios.defaults.headers.common[this.jwtConfig.current_user]
        window.location = "/login"
        this.counter = 0
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return apiService.post(this.jwtConfig.loginEndpoint, ...args)
    }

    refreshToken() {
        return apiService.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken()
        })
    }
}
