// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import settings from '@src/views/Settings/store'

const rootReducer = {
    auth,
    navbar,
    layout,
    settings
}

export default rootReducer
