import React, {useCallback, useMemo, useState} from 'react'
import axios from "axios"


export const AuthContext = React.createContext()

const AuthProvider = (props) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [user, setUser] = useState({})
    const [token, setToken] = useState(null)

    const signOut = () => {
        setUser(null)
        setLoggedIn(false)
        setToken(null)
        delete axios.defaults.headers.common.Authorization
        delete axios.defaults.headers.common['current_user']
        localStorage.removeItem('userData')
    }

    const signIn = useCallback((token, user, currentUserToken = null) => {
        //const decodedToken = jwt_decode(token)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        if (currentUserToken) axios.defaults.headers.common['current_user'] = `Bearer ${currentUserToken}`
        setLoggedIn(true)
        setToken(token)
        setUser({...user})
        localStorage.setItem(
            'userData',
            JSON.stringify({
                token,
                user,
                loggedIn: true
            })
        )


    }, [])

    const editUserData = (newUserdata) => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData) userData.user = newUserdata
        localStorage.setItem('userData', JSON.stringify(userData))
        setUser(newUserdata)
    }


    useMemo(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const currentUser = localStorage.getItem('currentUser')
        if (userData) {
            signIn(userData.token, userData.user, currentUser)
        }
    }, [signIn])

    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                signOut,
                signIn,
                user,
                setUser,
                token,
                editUserData
            }}
        >
            <>{props.children}</>
        </AuthContext.Provider>
    )
}

export default AuthProvider
