import Axios from 'axios'
import React from 'react'

class ApiService {

    constructor() {
        this.apiUrl = process?.env?.REACT_APP_REST_API_URL
    }

    post(endpoint, body) {
        const headers = {
            'Content-Type': 'application/json'
        }
        return Axios.post(`${this.apiUrl}/${endpoint}`, body, {headers})
    }

    put(endpoint, body) {
        const headers = {
            'Content-Type': 'application/json'
        }
        return Axios.put(`${this.apiUrl}/${endpoint}`, body, {headers})
    }

    get(endpoint) {
        const headers = {
            'Content-Type': 'application/json'
        }
        return Axios.get(`${this.apiUrl}/${endpoint}`, {headers})
    }

    delete(endpoint) {
        const headers = {
            'Content-Type': 'application/json'
        }

        return Axios.delete(`${this.apiUrl}/${endpoint}`, {headers})
    }

}

export default ApiService
